<template>
  <div style="width:100%">      
    <SchoolStatistics v-show="type == 'school'" @changeShowType="changeShowType"></SchoolStatistics>
    <GradeStatistics v-if="type == 'grade' " :param="param"  @changeShowType="changeShowType"></GradeStatistics>
    <ClassStatistics v-if="type == 'class' " :param="param" @changeShowType="changeShowType"></ClassStatistics>
  </div>
</template>

<script>
import SchoolStatistics from './components/SchoolStatistics'
import GradeStatistics from './components/GradeStatistics'
import ClassStatistics from './components/ClassStatistics'
import {  } from '@/api/index.js'
export default {
  name: 'statistics',
  components: {  SchoolStatistics,GradeStatistics,ClassStatistics},
  data() {
    return {
      type:'school',
      param:{
        schoolId:'',
        schoolName:'',
        year:'',
        semester:'',
        gradeId:'',
        gradeName:'',
        classId:'',
        className:''
      }
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,data){
      console.log('我是type',type)
      this.type = type;
      if(!data){
        return
      }
      this.param = {...this.param,...data}
    }
  }
}
</script>

<style>
</style>