<template>
  <div style="width:100%">
    <div class="head-region">
      <div class="back-btn">
        <el-button type="primary" size="mini" icon="el-icon-back" @click="backPre()">返回学校统计</el-button>
      </div>
      <div class="head-path">
        <span>订单统计</span>/<span >{{param.schoolName}}</span>
      </div>
    </div>
    
    
    <div class="search-box">
      <el-form inline size="small">
         <el-form-item label="年级">
            <el-select v-model="gradeId" placeholder="请选择" style="width:100px" @change="gradeChange" >
              <el-option label="全部" value=""></el-option>
              <el-option v-for="gradeInfo in gradeList" :key="gradeInfo.id" :label="gradeInfo.name" :value="gradeInfo.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-select v-model="classId" placeholder="请选择" @change="classChange" style="width:100px" :disabled="!gradeId">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="classInfo in classList" :key="classInfo.id" :label="classInfo.name" :value="classInfo.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="征订批次"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>   
            </el-select>
          </el-form-item>  
        <el-form-item label="支付时间">
          <el-date-picker v-model="paymentTimeRang"  type="daterange" align="right" style="width:220px;"
             unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
             value-format="yyyy-MM-dd"
             >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);"  :stripe="true" :border="true">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="年份" align="center" show-overflow-tooltip width="100">
        <template >
          <span >{{form.year}}年</span>
        </template>
      </el-table-column>
      <el-table-column label="学期" align="center" show-overflow-tooltip width="120">
        <template slot-scope="scope">
          <span >{{scope.row.semester==0?'春季学期':'秋季学期'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName +','+scope.row.regName+","+scope.row.streetName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="学校"  show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span >{{scope.row.schoolName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="年级"  show-overflow-tooltip align="center" width="100">
        <template slot-scope="scope">
          <span >{{scope.row.gradeName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级"  show-overflow-tooltip align="center" width="100">
        <template slot-scope="scope">
          <span >{{scope.row.className}}</span>
        </template>
      </el-table-column>
      <el-table-column label="总人数" align="center" prop="classStudentNumber" show-overflow-tooltip width="100"></el-table-column>
      <el-table-column label="征订人数" align="center" prop="subscribersCount" show-overflow-tooltip width="100"></el-table-column>
      <el-table-column label="征订率" align="center" prop="subscriptionRate" show-overflow-tooltip width="100"></el-table-column>

      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail('class', scope.row)">查看</el-button>
          <el-button type="text"  @click="exportClassOrderStatistics(scope.row)">导出班级订奶名单</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
  </div>
</template>

<script>
import { getGradeBySchoolId,getGradeStatisticsList,getClassByGradeId,exportClassOrderStatistics } from '@/api/index.js'

export default {
  name: 'capital',
  components: { },
  props: {
    param:{
      type: Object,
      default: () =>{}
    }
  }, 
  data() {
    return {
      showType: 0,
      editType: '',
      form: {
        year:'',
        semester:'',
        batch:'',
        status:'', 
        schoolId:'',
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:'',
        gradeName:'',
        className:''
      },
      list: [],
      total: 0,
      editType: '',
      paymentTimeRang:[],
      gradeList:[],   
      classList:[],
      gradeId:'',
      classId:''

    }
    
  },
  created() {
    this.form.schoolId = this.param.schoolId;
    this.form.semester = this.param.semester;
    this.form.year = this.param.year;
    this.getList();
    this.getGradeList();
  },
  methods: {
    backPre() {
      this.$emit('changeShowType', 'school')
    },
    editDetail(type,data) {
      let param = {
        schoolId: data.schoolId,
        schoolName: data.schoolName,
        semester: this.form.semester,
        year: this.form.year,
        gradeId:data.gradeId,
        gradeName:data.gradeName,
        classId:data.classId,
        className:data.className
      }
      this.$emit('changeShowType', type,param)
    },
    getList() {
      if(this.paymentTimeRang.length>0){
        this.form.startTime = this.paymentTimeRang[0];
      }
      if(this.paymentTimeRang.length>1){
        this.form.endTime = this.paymentTimeRang[1];
      }
      getGradeStatisticsList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    getGradeList(){
      let param={
        schoolId:this.form.schoolId
      };
      getGradeBySchoolId(param).then(res => {
        if (res.code != 200) return
        this.gradeList = res.data
      })  

    },
    gradeChange(gradeId){
      this.form.gradeName = '';
      if(!gradeId){
        this.classList = [];
        this.form.className='';
        return;
      }
      let obj = this.gradeList.find((item)=>{
        return item.id == gradeId;
      })
      this.form.gradeName = obj.name;
      let param={
        gradeId:gradeId
      };
      getClassByGradeId(param).then(res => {
        if (res.code != 200) return
        this.classList = res.data
      })   
    },
    classChange(classId){
      this.form.className = '';
      let obj = this.classList.find((item)=>{
        return item.id == classId;
      })
      this.form.className = obj.name;
    },
    exportClassOrderStatistics(classInfo){
      let param = {
          schoolId:this.form.schoolId,
          year: this.form.year,
          semester: this.form.semester,
          batch: this.form.batch,
          gradeClassNames:classInfo.gradeName+':'+classInfo.className
          //gradeName:classInfo.gradeName,
          //className:classInfo.className


        };
        exportClassOrderStatistics(param) 

    }
  }
}
</script>

<style lang="scss" scoped>
.head-region{
  display: flex;
  .back-btn{
    line-height: 30px;
    flex: 0 0 130px;
  }
  .head-path{
    flex:1;
    text-align: left;
    line-height: 32px;
    font-size:14px;
    span{
      margin-left:5px;
      margin-right:5px;
      
      color:#606266;
    }
  }
}
</style>