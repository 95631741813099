<template>
  <div style="width:100%">
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="年份：">
          <el-date-picker v-model="form.year" format="yyyy"  value-format="yyyy" :clearable="false" :editable="false" type="year" placeholder="请选择年份" style="width:100px"> </el-date-picker>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="form.semester" placeholder="请选择" style="width:100px">
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="征订批次"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>   
            </el-select>
          </el-form-item>  
          <el-form-item label="地区" >
            <ChooseArea ref="chooseArea" v-model="areaValue"  @changeArea="changeArea" :checkStrictly="true" :selectedLevel="4" style="width:250px" ></ChooseArea>
          </el-form-item>
          <el-form-item label="学校" >
            <el-select v-model="form.schoolId" placeholder="请选择" style="width:150px"  :disabled="(form.proId || form.cityId || form.regId || form.streetId)?false:true">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="schoolInfo in schoolList" :key="schoolInfo.id" :label="schoolInfo.name" :value="schoolInfo.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker v-model="paymentTimeRang"  type="daterange" align="right" style="width:220px;"
             unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
             value-format="yyyy-MM-dd"
             >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="学校"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);"  :stripe="true" :border="true">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="年份" align="center" show-overflow-tooltip width="150">
        <template >
          <span >{{form.year}}年</span>
        </template>
      </el-table-column>
      <el-table-column label="学期" align="center" show-overflow-tooltip width="150">
        <template slot-scope="scope">
          <span >{{scope.row.semester==0?'春季学期':'秋季学期'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          <span >{{scope.row.proName+","+scope.row.cityName +','+scope.row.regName+","+scope.row.streetName}}</span><br />
        </template>
      </el-table-column>
      <el-table-column label="学校"  show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span >{{scope.row.schoolName}}</span><br />
        </template>
      </el-table-column>
      <el-table-column label="总人数" align="center" prop="totalStudentCount" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column label="征订人数" align="center" prop="subscribersCount" show-overflow-tooltip width="150"></el-table-column>
      <el-table-column label="征订率" align="center" prop="subscriptionRate" show-overflow-tooltip width="150"></el-table-column>

      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail('grade',scope.row)">查看</el-button>
          <el-button type="text"  @click="exportSchoolOrderStatistics(scope.row.schoolId)">导出学校统计</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
  </div>
</template>

<script>
import { getAllSchoolList,getSchoolStatisticsList,exportSchoolOrderStatistics } from '@/api/index.js'
import axios from 'axios';
import ChooseArea from '@/components/ChooseArea/index.vue'

export default {
  name: 'capital',
  components: { ChooseArea },
  data() {
    return {
      showType: 0,
      editType: '',
      form: {
        year:'',
        semester:'',
        batch:'',
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        status:'', 
        schoolId:'',
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:''
      },
      list: [],
      total: 0,
      editType: '',
      areaValue:['','','',''],
      paymentTimeRang:[],
      schoolList:[],   
    }
    
  },
  created() {
    let nowDate = new Date();
    this.form.year = nowDate.getFullYear()+'';
    let currMonth = nowDate.getMonth()+1;
    if(currMonth>6){
      this.form.semester = '1';
    }else{
      this.form.semester = '0';
    }
    this.getList();
  },
  methods: {
    
    changeArea(selectedData){
      this.form.proId = selectedData.proId;
      this.form.cityId=selectedData.cityId;
      this.form.regId=selectedData.regId;
      this.form.streetId=selectedData.streetId;
      if(this.form.proId || this.form.cityId || this.form.regId || this.form.streetId){
        this.getSchoolList();
      }else{
        this.schoolList=[];
        this.form.schoolId = '';
      }
    },
    editDetail(type,data) {
      let param = {
        schoolId: data.schoolId,
        schoolName: data.schoolName,
        semester: this.form.semester,
        year: this.form.year
      }
      this.$emit('changeShowType', type,param)
    },
    getList() {
      if(this.paymentTimeRang.length>0){
        this.form.startTime = this.paymentTimeRang[0];
      }
      if(this.paymentTimeRang.length>1){
        this.form.endTime = this.paymentTimeRang[1];
      }
      getSchoolStatisticsList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    getSchoolList(){
      let param = {
          isPage:0,
          proId: this.form.proId,
          cityId: this.form.cityId,
          regId: this.form.regId,
          streetId: this.form.streetId
        };
      getAllSchoolList(param).then(res => {
        if (res.code != 200) return
        this.schoolList = res.data
      })   

    },
    exportSchoolOrderStatistics(schoolId){
      let param = {
          schoolId:schoolId,
          year: this.form.year,
          semester: this.form.semester,
          batch: this.form.batch

        };
        exportSchoolOrderStatistics(param) 

    }
  }
}
</script>

<style>
</style>