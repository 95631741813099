<template>
  <div style="width:100%">
    <div class="head-region">
      <div class="back-btn">
        <el-button type="primary" size="mini" icon="el-icon-back" @click="backPre()">返回班级统计</el-button>
      </div>
      <div class="head-path">
        <span >订单统计</span>/<span >{{param.schoolName}}</span>
        /<span >{{param.gradeName}}</span> /<span >{{param.className}}</span>
      </div>
    </div>
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="征订批次"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>   
            </el-select>
          </el-form-item>  
        <el-form-item label="支付时间">
          <el-date-picker v-model="paymentTimeRang"  type="daterange" align="right" style="width:220px;"
             unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" 
             value-format="yyyy-MM-dd"
             >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名字、手机号"></el-input>
          </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="list" style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);"  :stripe="true" :border="true">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>

      <el-table-column label="年级"  show-overflow-tooltip align="center" >
        <template slot-scope="scope">
          <span >{{scope.row.gradeName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="班级"  show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span >{{scope.row.className}}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center" prop="name" show-overflow-tooltip ></el-table-column>
      <el-table-column label="手机号码" align="center" prop="phone" show-overflow-tooltip></el-table-column>
      <el-table-column label="套餐" align="center" prop="packageName" show-overflow-tooltip ></el-table-column>
      <el-table-column label="支付时间" align="center" prop="paymentTime" show-overflow-tooltip ></el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
  </div>
</template>

<script>
import { getClassStatisticsList } from '@/api/index.js'

export default {
  name: 'capital',
  components: { },
  props: {
    param:{
      type: Object,
      default: () =>{}
    }
  }, 
  data() {
    return {
      showType: 0,
      editType: '',
      form: {
        year:'',
        semester:'',
        batch:'',
        status:'', 
        schoolId:'',
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:'',
        gradeName:'',
        className:'',
        search:''
      },
      list: [],
      total: 0,
      editType: '',
      paymentTimeRang:[],
      gradeList:[],   
      classList:[],
      gradeId:'',
      classId:''

    }
    
  },
  created() {
    this.form.schoolId = this.param.schoolId;
    this.form.semester = this.param.semester;
    this.form.year = this.param.year;
    this.form.gradeName = this.param.gradeName;
    this.form.className = this.param.className;
    this.getList();
  },
  methods: {
    backPre() {
      this.$emit('changeShowType', 'grade')
    },
    editDetail(type,data) {
      let param = {
        schoolId: data.schoolId,
        schoolName: data.schoolName,
        semester: this.form.semester,
        year: this.form.year,
        classId:data.classId,
        className:data.className
      }
      this.$emit('changeShowType', type,param)
    },
    getList() {
      if(this.paymentTimeRang.length>0){
        this.form.startTime = this.paymentTimeRang[0];
      }
      if(this.paymentTimeRang.length>1){
        this.form.endTime = this.paymentTimeRang[1];
      }
      getClassStatisticsList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
   
  }
}
</script>

<style lang="scss" scoped>
.head-region{
  display: flex;
  .back-btn{
    line-height: 30px;
    flex: 0 0 130px;
  }
  .head-path{
    flex:1;
    text-align: left;
    line-height: 32px;
    font-size:14px;
    span{
      margin-left:5px;
      margin-right:5px;
      
      color:#606266;
    }
  }
}
</style>